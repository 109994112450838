// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("@/assets/img/article/realtime/1.png");
var ___HTML_LOADER_IMPORT_1___ = require("@/assets/img/article/realtime/2.png");
var ___HTML_LOADER_IMPORT_2___ = require("@/assets/img/article/realtime/3.png");
var ___HTML_LOADER_IMPORT_3___ = require("@/assets/img/article/realtime/4.png");
var ___HTML_LOADER_IMPORT_4___ = require("@/assets/img/article/realtime/5.png");
var ___HTML_LOADER_IMPORT_5___ = require("@/assets/img/article/realtime/6.png");
var ___HTML_LOADER_IMPORT_6___ = require("@/assets/img/article/realtime/7.png");
var ___HTML_LOADER_IMPORT_7___ = require("@/assets/img/article/realtime/8.png");
var ___HTML_LOADER_IMPORT_8___ = require("@/assets/img/article/realtime/9.png");
var ___HTML_LOADER_IMPORT_9___ = require("@/assets/img/article/realtime/10.png");
var ___HTML_LOADER_IMPORT_10___ = require("@/assets/img/article/realtime/11.png");
var ___HTML_LOADER_IMPORT_11___ = require("@/assets/img/article/realtime/12.png");
var ___HTML_LOADER_IMPORT_12___ = require("@/assets/img/article/realtime/13.png");
var ___HTML_LOADER_IMPORT_13___ = require("@/assets/img/article/realtime/14.png");
var ___HTML_LOADER_IMPORT_14___ = require("@/assets/img/article/realtime/15.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var ___HTML_LOADER_REPLACEMENT_4___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_4___);
var ___HTML_LOADER_REPLACEMENT_5___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_5___);
var ___HTML_LOADER_REPLACEMENT_6___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_6___);
var ___HTML_LOADER_REPLACEMENT_7___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_7___);
var ___HTML_LOADER_REPLACEMENT_8___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_8___);
var ___HTML_LOADER_REPLACEMENT_9___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_9___);
var ___HTML_LOADER_REPLACEMENT_10___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_10___);
var ___HTML_LOADER_REPLACEMENT_11___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_11___);
var ___HTML_LOADER_REPLACEMENT_12___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_12___);
var ___HTML_LOADER_REPLACEMENT_13___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_13___);
var ___HTML_LOADER_REPLACEMENT_14___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_14___);
var code = "<h1 id=\"realtime-monitor\">Realtime Monitor</h1> <h6 id=\"july-2018\">July, 2018</h6> <hr> <p>定時對 <a href=\"https://www.17ce.com/\">17CE</a> 和 <a href=\"http://www.webkaka.com/\">Webkaka</a> 等測速網站爬蟲，取得各檢測點的性能結果，截圖當前頁面快照，並儲存與 MongoDB。在 dashboard 可掌握最新結果與各項指標，亦可查詢歷史紀錄。</p> <h2 id=\"系統架構圖\">系統架構圖</h2> <p><img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"\"></p> <h2 id=\"功能\">功能</h2> <p><img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" alt=\"\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" alt=\"\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\" alt=\"\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_4___ + "\" alt=\"\"></p> <h2 id=\"rwd\">RWD</h2> <p><img src=\"" + ___HTML_LOADER_REPLACEMENT_5___ + "\" alt=\"\"></p> <h2 id=\"ui\">UI</h2> <p><img src=\"" + ___HTML_LOADER_REPLACEMENT_6___ + "\" alt=\"\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_7___ + "\" alt=\"\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_8___ + "\" alt=\"\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_9___ + "\" alt=\"\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_10___ + "\" alt=\"\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_11___ + "\" alt=\"\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_12___ + "\" alt=\"\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_13___ + "\" alt=\"\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_14___ + "\" alt=\"\"></p> ";
// Exports
module.exports = code;