// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("@/assets/img/article/emo/cover.png");
var ___HTML_LOADER_IMPORT_1___ = require("@/assets/img/article/emo/login.png");
var ___HTML_LOADER_IMPORT_2___ = require("@/assets/img/article/emo/manage-thesis.png");
var ___HTML_LOADER_IMPORT_3___ = require("@/assets/img/article/emo/manage-research.png");
var ___HTML_LOADER_IMPORT_4___ = require("@/assets/img/article/emo/manage-member.png");
var ___HTML_LOADER_IMPORT_5___ = require("@/assets/img/article/emo/manage-carousel.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var ___HTML_LOADER_REPLACEMENT_4___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_4___);
var ___HTML_LOADER_REPLACEMENT_5___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_5___);
var code = "<h1 id=\"《電磁最佳化實驗室》網站\">《電磁最佳化實驗室》網站</h1> <h6 id=\"mar-2018---oct-2018\">Mar, 2018 - Oct, 2018</h6> <hr> <p>這是台北科技大學之電磁最佳化實驗室 (EM Optimization Lab) 的網站，包含 RWD 來增進不同裝置上的瀏覽體驗。</p> <p>前端主要以 Vue 架構，使用 Sass 和 Pug 搭配 Gulp 自動化流程工具編譯，使用 Firebase 作為資料庫。</p> <p><img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"首頁\"></p> <h2 id=\"後台管理\">後台管理</h2> <p>網站提供了簡單易用的後台介面，讓管理者能方便快速地更新頁面內容，如<strong>修改首頁近期活動</strong>、<strong>上傳輪播照片</strong>、 <strong>修改實驗室成員</strong> 等。</p> <p>考慮到網站部署空間的限制，因為學校提供的上傳空間只支援靜態網頁，所以資料庫選擇使用 <strong>Firebase</strong>，其最大的特色就是只靠靜態的前端網頁就可以操作 database。此外它也可以靠 JS 來上傳檔案到儲存空間，也提供驗證登入的功能，讓擁有權限的使用者才可進入後台編輯。</p> <p><img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" alt=\"登入後台\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" alt=\"後台介面\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\" alt=\"編輯研究項目\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_4___ + "\" alt=\"編輯實驗室成員\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_5___ + "\" alt=\"上傳輪播照片(可拖曳排序)\"></p> <h2 id=\"demo\">Demo</h2> <p><a href=\"https://myweb.ntut.edu.tw/~yschen/\">https://myweb.ntut.edu.tw/~yschen/</a></p> <iframe src=\"https://ghbtns.com/github-btn.html?user=ngseke&repo=emo&type=star&count=false\" frameborder=\"0\" scrolling=\"0\" width=\"150\" height=\"20\"></iframe> ";
// Exports
module.exports = code;