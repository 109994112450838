// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("@/assets/img/article/shanlinliang/cover.png");
var ___HTML_LOADER_IMPORT_1___ = require("@/assets/img/article/shanlinliang/sll1.png");
var ___HTML_LOADER_IMPORT_2___ = require("@/assets/img/article/shanlinliang/sll4.png");
var ___HTML_LOADER_IMPORT_3___ = require("@/assets/img/article/shanlinliang/sll5.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var code = "<h1 id=\"扇林涼\">扇林涼</h1> <hr> <p><strong>『扇林涼 Shanlinliang』</strong> 是一個虛構的涼扇品牌，運用簡約設計文字營造出商品的高級感。</p> <p><img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"\"></p> <p><img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" alt=\"\"></p> <p><img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" alt=\"\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\" alt=\"\"></p> ";
// Exports
module.exports = code;