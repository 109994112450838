// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("@/assets/img/article/gomoku/title.png");
var ___HTML_LOADER_IMPORT_1___ = require("@/assets/img/article/gomoku/register.png");
var ___HTML_LOADER_IMPORT_2___ = require("@/assets/img/article/gomoku/room.png");
var ___HTML_LOADER_IMPORT_3___ = require("@/assets/img/article/gomoku/cover.png");
var ___HTML_LOADER_IMPORT_4___ = require("@/assets/img/article/gomoku/mobile.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var ___HTML_LOADER_REPLACEMENT_4___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_4___);
var code = "<h1 id=\"gomoku-五子棋對戰\">Gomoku 五子棋對戰</h1> <h6 id=\"oct-2018---nov-2018\">Oct, 2018 - Nov, 2018</h6> <hr> <p><img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"\"></p> <p><a href=\"https://gomoku.ga/#/about\">Gomoku: 五子棋對戰</a>是簡約的五子棋遊戲，是與小夥伴在悠閒午後消磨時間的良伴。</p> <p><img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" alt=\"註冊畫面\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" alt=\"大廳房間列表\"></p> <p><img src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\" alt=\"遊戲對戰與聊天室\"></p> <p><img src=\"" + ___HTML_LOADER_REPLACEMENT_4___ + "\" alt=\"行動裝置版本\"></p> <h2 id=\"demo\">Demo</h2> <p><a href=\"https://gomoku.ga/\">https://gomoku.ga/</a></p> <iframe src=\"https://ghbtns.com/github-btn.html?user=ngseke&repo=gomoku&type=star&count=false\" frameborder=\"0\" scrolling=\"0\" width=\"150\" height=\"20\"></iframe> ";
// Exports
module.exports = code;