// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("@/assets/img/article/boss/boss1.png");
var ___HTML_LOADER_IMPORT_1___ = require("@/assets/img/article/boss/boss2.png");
var ___HTML_LOADER_IMPORT_2___ = require("@/assets/img/article/boss/boss3.png");
var ___HTML_LOADER_IMPORT_3___ = require("@/assets/img/article/boss/boss4.png");
var ___HTML_LOADER_IMPORT_4___ = require("@/assets/img/article/boss/boss5.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var ___HTML_LOADER_REPLACEMENT_4___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_4___);
var code = "<h1 id=\"boss-茶店\">BOSS 茶店</h1> <h6 id=\"nov-2017---jan-2018\">Nov, 2017 - Jan, 2018</h6> <h6 id=\"team-member-吳品頤、余鎧企、a-hrefabout黃省喬a、趙振廷\">Team Member: 吳品頤、余鎧企、<a href=\"/about\">黃省喬</a>、趙振廷</h6> <hr> <p>這是一個在線飲料購物系統 (Beverage Online Shop System)，簡稱BOSS，是一個簡單易用，介面時尚清爽的飲料購物平台。本專案後端以PHP語言撰寫，前端以Bootstrap的架構搭配HTML5和CSS3作為基礎進行版型設計。</p> <p><img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"\"></p> <p>在此系統中可以根據不同使用者身份，包括顧客、員工及管理員，提供各種不同的操作功能。 我們提供簡單直覺易懂的操作界面，讓顧客可以瀏覽本店所有商品、根據分類或搜尋條件取得商品資訊、對於產品及訂單進行打分或評論、查看。 員工可以上下架商品、管理商品之庫存及資訊、查看當前所有訂單資訊。管理者可以新增移除或修改會員資訊。</p> <h2 id=\"介面\">介面</h2> <p><img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" alt=\"隨機展示熱銷商品\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" alt=\"顯示當前優惠活動\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\" alt=\"商品列表\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_4___ + "\" alt=\"商品詳情頁面\"></p> <h2 id=\"demo\">Demo</h2> <p><del><a href=\"http://boss.ngseke.me/\">http://boss.ngseke.me/</a></del> 已下架</p> <iframe src=\"https://ghbtns.com/github-btn.html?user=ngseke&repo=boss&type=star&count=false\" frameborder=\"0\" scrolling=\"0\" width=\"150\" height=\"20\"></iframe> ";
// Exports
module.exports = code;